<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#tab-1">ລູກຄ້າໃໝ່</v-tab>
        <v-tab href="#tab-2">ຂໍ້ມູນລູກຄ້າ</v-tab>
<!--        <v-tab href="#tab-3">ລາຍລະອຽດຂໍ້ມູນລູກຄ້າ</v-tab>-->
        <v-tab href="#tab-3">ລູກຄ້າຍົກເລີກ</v-tab>
        <v-tab href="#tab-4">ລາຍງານລູກຄ້າສັງຊື້ນ້ຳ</v-tab>
        <v-tab href="#tab-5">ລາຍງານລູກຄ້າອາດຈະບໍ່ຊື້ນ້ຳ</v-tab>
      </v-tabs>
      <!-- <hr /> -->

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text>
              <NewCustomer />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text>
              <Customer />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
<!--      <v-tabs-items v-model="tab">-->
<!--        <v-tab-item value="tab-3">-->
<!--          <v-card flat>-->
<!--            <v-card-text>-->
<!--              <CustomerDetail />-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--        </v-tab-item>-->
<!--      </v-tabs-items>-->

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-3">
          <v-card flat>
            <v-card-text>
              <CustomerClose />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-4">
          <v-card flat>
            <v-card-text>
              <ReportCustomerSale />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-5">
          <v-card flat>
            <v-card-text>
              <ReportCustomerInactive />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

    </v-card>
  </div>
</template>

<script>
import NewCustomer from "@views/Users/Customer/tabs/new_customer";
import Customer from "@views/Users/Customer/tabs/customer";
// import CustomerDetail from "@views/Users/Customer/tabs/customer_detail";
import CustomerClose from "@views/Users/Customer/tabs/customer_close";
import ReportCustomerSale from "../../../views/Report/customer/customer_report_sale";
import ReportCustomerInactive from "../../../views/Report/customer/customer_report_no_sale";

export default {
  components: {
    NewCustomer,
    Customer,
    // CustomerDetail,
    CustomerClose,
    ReportCustomerSale,
    ReportCustomerInactive,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    if (this.$route.query.tab == "new_customer") {
      this.tab = "tab-1";
    }else if (this.$route.query.tab == "customer") {
      this.tab = "tab-2";
    }
    // else if (this.$route.query.tab == "customer-detail") {
    //   this.tab = "tab-3";
    // }
    else if (this.$route.query.tab == "customer-close") {
      this.tab = "tab-3";
    } else if (this.$route.query.tab == "report-customer-sale") {
      this.tab = "tab-4";
    }
    else if (this.$route.query.tab == "customer-inactive") {
      this.tab = "tab-5";
    }
  },
  watch: {
    tab: function (value) {
      if (value == "tab-1") {
        this.$router
            .push({ name: "Customer", query: { tab: "new_customer" } })
            .catch(() => {});
      } else if (value == "tab-2") {
        this.$router
          .push({ name: "Customer", query: { tab: "customer" } })
          .catch(() => {});
      }
      // else if (value == "tab-3") {
      //   this.$router
      //     .push({ name: "Customer", query: { tab: "customer-detail" } })
      //     .catch(() => {});
      // }
      else if (value == "tab-3") {
        this.$router
          .push({ name: "Customer", query: { tab: "customer-close" } })
          .catch(() => {});
      }else if (value == "tab-4") {
        this.$router
            .push({ name: "Customer", query: { tab: "report-customer-sale" } })
            .catch(() => {});
      }else if (value == "tab-5") {
        this.$router
            .push({ name: "Customer", query: { tab: "customer-inactive" } })
            .catch(() => {});
      }
    },
  },
};
</script>

<style>
</style>
