<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ລູກຄ້າຢຸດສັ່ງຊື້ນ້ຳ</h2></v-col>
      <!--  <v-col class="al-end"> <v-btn color="primary">Export</v-btn></v-col>-->
    </v-row>
    <v-row>
      <v-col cols="5">
        <h3 class="mb-2">
          ລວມຈຳນວນລູກຄ້າຍົກເລີກຊື້ນ້ຳ:
          {{ Intl.NumberFormat().format(totalCustomer) }}
        </h3>
      </v-col>

      <v-col cols="5">
        <v-text-field
          outlined
          dense
          clearable
          label="ຄົ້ນຫາ ດ້ວຍ ລະຫັດ, ຊື່"
          type="text"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          @keyup.enter="Search()"
        >
        </v-text-field>
      </v-col>
      <v-col class="al-end" col="2">
        <v-btn
          color="primary"
          @click="exportData()"
          :loading="exportLoading"
          :disabled="exportLoading"
          >Export</v-btn
        ></v-col
      >
    </v-row>
    <!-- <hr /> -->
    <v-data-table
      :headers="headers"
      :items="customers"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.name="{ item }">
        {{ item.name }} ({{ item.village }})
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ moment(item.reject_date).format("DD-MM-YYYY") }}
      </template>
      <!--Action -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="viewCustomer(item.id)"
          >mdi-eye</v-icon
        >
        <v-icon small class="mr-2" @click="updateCustomer(item.id)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <br />
    <template>
      <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchData()"
      ></Pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
import { GetOldValueOnInput } from "@/Helpers/GetValue";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: false,
      bankLoading: false,
      TableLoading: false,
      exportLoading: false,
      customers: [],
      // invoiceDetail: {},
      // customerDetail: {},
      // createdByDetail: {},

      //Pagination
      offset: 12,
      pagination: {},
      per_page: 15,
      totalCustomer: 0,
      search: "",
      oldVal: "",

      headers: [
        { text: "ID", align: "start", value: "customer_code", sortable: false },
        { text: "ຊື່ລູກຄ້າ", align: "start", value: "name", sortable: false },
        { text: "ຈຳນວນຕຸກນ້ຳ", value: "total_bottle", align: "center" },
        { text: "ຄ່າມັດຈຳ", value: "totalBondingFee", align: "center" },
        {
          text: "ວັນທີເຊົາໃຊ້ບໍລິການ",
          value: "reject_date",
          align: "center",
        },
        { text: "ເຫດຜົນ", value: "remark", align: "start" },
        { text: "", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  metaInfo() {
    return {
      title: `ລູກຄ້າ - ຢຸດໃຊ້ບໍລິການ`,
      meta: [
        { name: 'description', content: 'ລູກຄ້າ - ຢຸດໃຊ້ບໍລິການ'},
      ]
    }
  },
  methods: {
    updateCustomer(id) {
      this.$router.push({name: 'CustomerUpdate', params: {id: id}});
    },
    viewCustomer(id) {
      this.$router.push({
        name: "Report-Customer-Detail",
        params: { id: id },
      });
    },
    fetchData() {
      // this.TableLoading = true;
      this.$admin
        .get("list-close-customer-service", {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.search,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.customers = res.data.data;
            this.totalCustomer = res.data.total;
            this.pagination = res.data;
            this.TableLoading = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$axios
        .get("export/customer/stop/services", { responseType: "blob" })
        .then((res) => {
          setTimeout(() => {
            const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute(
              "download",
              "customers-none-active" + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            this.exportLoading = false;
          }, 100);
        })
        .catch(() => {
          this.exportLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    Search() {
      GetOldValueOnInput(this);
    },
  },

  watch: {
    search: function (value) {
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    // this.viewInvoice();
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
