<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          ລູກຄ້າລວມທັງໝົດ:
          <span class="primary-color">({{ count }})</span>
        </h2>
      </v-col>
      <v-col>
        <v-text-field
            outlined
            dense
            clearable
            label="ຄົ້ນຫາ ດ້ວຍລະຫັດ, ຊື່, ເບີໂທ"
            type="text"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            @keyup.enter="Search()"
        >
        </v-text-field>
      </v-col>
      <v-col class="al-end" col="2">
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>
    <v-row justify="end">
      <template>
        <v-btn class="btn-primary" fab small dark @click="routeCreate()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-row>
    <v-data-table
        :headers="headers"
        :items="customers"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1 scroll-x-reverse-transition"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
        :search="search"
    >
      <!--Action -->
      <!--
      <div>
        <v-chip v-if="newcustomer" color="success">{{ item.customer_code }}</v-chip>
      </div>
      -->
      <template v-slot:item.deliverable_time="{item}">
        <div>{{ getDelivery(item.deliverable_time) }}</div>
      </template>
      <template v-slot:item.package_detail="{item}">
        <div> {{ Intl.NumberFormat().format(item.package_detail.price) }}</div>
      </template>

      <template v-slot:item.image="{item}">
        <v-avatar
            size="36px"
            @click="showFullIamage(item,'profile')"
        >
          <img v-if="item.profile" :src="item.profile"/>
        </v-avatar>
      </template>

      <template v-slot:item.home_image="{item}">
        <v-avatar
            size="36px"
            @click="showFullIamage(item,'home')"
        >
          <img v-if="item.home_image" :src="item.home_image"/>
        </v-avatar>
      </template>
      <template v-slot:item.village="{ item }">
        <div>{{ item.village }}, {{ item.district }}</div>
      </template>
      <template v-slot:item.status="{ item }">
        <div :class="getstatusColor(item.status)">{{ item.status }}</div>
      </template>
      <template v-slot:item.days="{ item }">
        <div
            v-for="(data, index) in item.delivery_day"
            :key="index"
        >
          <div>{{ data.name }},</div>
        </div>
      </template>
      <template v-slot:item.createdDate="{ item }">
        <div :class="getDateCreated(item.createdDate)">
          {{ moment(item.createdDate).format("DD-MM-YYYY") }}
        </div>
        <!-- {{item.created_at}} -->
      </template>


      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                medium
                class="mr-2"
            >mdi-dots-vertical
            </v-icon
            >
          </template>
          <v-list>
            <v-list-item link @click="viewInvoice(item)">
              <v-list-item-title>
                <v-icon small class="mr-2" @click="viewInvoice(item)">mdi-eye</v-icon>
                ລາຍລະອຽດ
              </v-list-item-title>
            </v-list-item>

            <v-list-item link @click="updateCustomer(item.id)">
              <v-list-item-title>
                <v-icon small class="mr-2" @click="updateCustomer(item.id)">
                  mdi-pencil
                </v-icon>
                ແກ້ໄຂ
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="closeCustomer(item.id)">
              <v-list-item-title>
                <v-icon small class="mr-2 error--text" @click="closeCustomer(item.id)"
                >mdi-close
                </v-icon
                >
                ປິດບໍລິການ
              </v-list-item-title>
            </v-list-item>

            <v-list-item link @click="OpenchangePassword(item.userId)">
              <v-list-item-title>
                <v-icon small class="mr-2" @click="OpenchangePassword(item.userId)">
                  mdi-key
                </v-icon>
                ປ່ຽນລະຫັດຜ່ານ
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="openPoint(item)">
              <v-list-item-title>
                <v-icon small class="mr-3 primary-color" @click="openPoint(item)"
                >mdi-star-four-points</v-icon
                >
                ເພີ່ມຄະແນນ
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click="openModalUpdateBondingFees(item.id)">
              <v-list-item-title>
                <v-icon
                    small
                    class="mr-2 primary-color"
                    @click="openModalUpdateBondingFees(item.id)"
                >
                  mdi-cash-plus
                </v-icon>
                ແກ້ໄຂເງິນມັດຈຳ
              </v-list-item-title>
            </v-list-item>

            <v-list-item link @click="deleteItem(item.id)">
              <v-list-item-title>
                <v-icon small class="mr-2 error--text" @click="deleteItem(item.id)"> mdi-delete</v-icon>
                ລຶບ
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>


<!--      <template v-slot:item.actions="{ item }">-->
<!--        <v-icon small class="mr-2" @click="viewInvoice(item)">mdi-eye</v-icon>-->
<!--        <v-icon small class="mr-2" @click="updateCustomer(item.id)">-->
<!--          mdi-pencil-->
<!--        </v-icon>-->
<!--        <v-icon small class="mr-2" @click="closeCustomer(item.id)"-->
<!--        >mdi-close-->
<!--        </v-icon-->
<!--        >-->
<!--        <v-icon small class="mr-2" @click="OpenchangePassword(item.userId)">-->
<!--          mdi-key-->
<!--        </v-icon>-->
<!--        <v-icon small @click="deleteItem(item.id)"> mdi-delete</v-icon>-->
<!--      </template>-->
      <template>

      </template>
    </v-data-table>
    <br/>
    <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchData()"
    ></Pagination>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Add Customer</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        label="ລະຫັດລູກຄ້າ *"
                        required
                        v-model="customer.customer_code"
                        :rules="customerCodeRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.customer_code }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ຜູ້ຮັບຜິດຊອບ *"
                        required
                        v-model="customer.organization_name"
                        :rules="organizeNameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ຊື່ລູກຄ້າ *"
                        required
                        v-model="customer.name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ນາມສະກຸນ"
                        required
                        v-model="customer.surname"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ເບີໂທ *"
                        required
                        v-model="customer.phone"
                        :rules="phoneRules"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Email"
                        v-model="customer.email"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ຊື່ບັນຊີທະນາຄານ"
                        v-model="customer.account_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ເລກບັນຊີ"
                        type="number"
                        class="input-number"
                        v-model="customer.account_number"
                    ></v-text-field>
                    <!-- <p class="errors">
                      {{ server_errors.email }}
                    </p> -->
                  </v-col>

                  <v-col cols="6">
                    <v-select
                        required
                        v-model="selectedCustomerType"
                        :items="customerType"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກປະເພດລູກຄ້າ *"
                        :rules="ruleRole"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="6">
                    <v-select
                        required
                        v-model="selectedPackage"
                        :items="packages"
                        item-text="name"
                        item-value="id"
                        label="ປະເພດແພັກເກດ *"
                        :rules="rulePackage"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        required
                        v-model="sourceValue"
                        :items="sources"
                        item-text="name"
                        item-value="value"
                        label="ທີ່ມາຂອງລູກຄ້າ *"
                        :rules="ruleSource"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        required
                        v-model="deliverable_time_value"
                        :items="deliverable_time"
                        item-text="name"
                        item-value="value"
                        label="ເວລາທີ່ສະດວກສົ່ງ *"
                        :rules="ruleTime"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        dense
                        required
                        v-model="selectedDistrict"
                        :items="listDistricts"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກເມືອງ *"
                        :rules="ruleDistrict"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                        dense
                        required
                        v-model="selectedVillage.id"
                        :items="listVillages"
                        item-text="name"
                        item-value="id"
                        label="ບ້ານ *"
                        :rules="ruleVillage"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                        class="ml-2 mr-0"
                        fab
                        small
                        dark
                        color="btn-primary"
                        @click="OpenAddVillage(listDistricts)"
                    >
                      <v-icon dark> mdi-plus</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="ຈຸດສັງເກດ"
                        v-model="customer.address"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Latitude"
                        v-model="center.lat"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Longitude"
                        v-model="center.lng"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <gmap-autocomplete
                        ref="searchInput"
                        class="input text-field"
                        placeholder="ຄົ້ນຫາເເຜນທີ່..."
                        @place_changed="setPlace"
                        :options="{
                        fields: ['geometry', 'formatted_address', 'name'],
                      }"
                    >
                    </gmap-autocomplete>
                    <span class="horizontal-divider"></span>
                  </v-col>
                  <v-col cols="12" class="mb-4">
                    <GmapMap
                        :center="center"
                        :zoom="16"
                        style="width: 100%; height: 450px"
                        :disableDefaultUI="true"
                    >
                      <GmapMarker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          @click="center = m.position"
                          :draggable="true"
                          @dragend="onLocation"
                          :icon="markerOptions"
                          :animation="2"
                          ref="markers"
                      />
                    </GmapMap>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="AddItem()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->

    <ModalEdit>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Update Customer</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                        label="ລະຫັດລູກຄ້າ *"
                        required
                        v-model="customer_edit.customer_code"
                        :rules="customerCodeRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.customer_code }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ຜູ້ຮັບຜິດຊອບ *"
                        required
                        v-model="customer_edit.organization_name"
                        :rules="organizeNameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ຊື່ *"
                        required
                        v-model="customer_edit.name"
                        :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ນາມສະກຸນ"
                        v-model="customer_edit.surname"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ເບີໂທ *"
                        required
                        v-model="customer_edit.phone"
                        :rules="phoneRules"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.phone }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Email"
                        v-model="customer_edit.email"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.email }}
                    </p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ຊື່ບັນຊີທະນາຄານ"
                        v-model="customer_edit.account_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="ເລກບັນຊີ"
                        v-model="customer_edit.account_number"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        required
                        v-model="customer_edit.customer_type_id"
                        :items="customerType"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກປະເພດລູກຄ້າ *"
                        :rules="ruleRole"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        required
                        v-model="customer_edit.packageId"
                        :items="packages"
                        item-text="name"
                        item-value="id"
                        label="ປະເພດແພັກເກດ *"
                        :rules="rulePackage"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        required
                        v-model="customer_edit.source"
                        :items="sources"
                        item-text="name"
                        item-value="value"
                        label="ທີ່ມາຂອງລູກຄ້າ *"
                        :rules="ruleSource"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        required
                        v-model="customer_edit.deliverable_time"
                        :items="deliverable_time"
                        item-text="name"
                        item-value="value"
                        label="ເວລາທີ່ສະດວກສົ່ງ *"
                        :rules="ruleTime"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                        dense
                        required
                        v-model="customer_edit.district_id"
                        :items="listDistricts"
                        item-text="name"
                        item-value="id"
                        label="ເລືອກເມືອງ *"
                        :rules="ruleDistrict"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="5">
                    <v-autocomplete
                        dense
                        required
                        v-model="customer_edit.villageID"
                        :items="listVillages"
                        item-text="name"
                        item-value="id"
                        label="ບ້ານ *"
                        :rules="ruleVillage"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                        class="ml-2 mr-0"
                        fab
                        small
                        dark
                        color="btn-primary"
                        @click="OpenAddVillage()"
                    >
                      <v-icon dark> mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="ຈຸດສັງເກດ (ຮ່ອມ,ຖະໜົນ)"
                        required
                        v-model="customer_edit.address"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        required
                        v-model="customer_edit.status"
                        :items="status"
                        item-text="name"
                        item-value="name"
                        label="ເລືອກສະຖານະ*"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Latitude"
                        v-model="customer_edit.latitude"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        label="Longitude"
                        v-model="customer_edit.longitude"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <gmap-autocomplete
                        ref="searchInput"
                        class="input text-field"
                        placeholder="ຄົ້ນຫາເເຜນທີ່..."
                        @place_changed="setPlace"
                        :options="{
                        fields: ['geometry', 'formatted_address', 'name'],
                      }"
                    >
                    </gmap-autocomplete>
                    <span class="horizontal-divider"></span>
                  </v-col>
                  <v-col cols="12" class="mb-4">
                    <GmapMap
                        :center="center"
                        :zoom="16"
                        style="width: 100%; height: 450px"
                    >
                      <GmapMarker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          @click="center = m.position"
                          :draggable="true"
                          @dragend="onLocation"
                          :icon="markerOptions"
                          :animation="2"
                          ref="markers"
                      />
                    </GmapMap>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="UpdateItem()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loadingView"
              :disabled="loadingView"
              @click="deleteItemConfirm"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
    <AddVillage @filter_village="RefreshVillage()"/>
    <!-- View Modal -->

    <ModalView>
      <template @close="close">
        <v-card mdi-map-marker-circle>
          <v-card-title>
            <span class="headline"
            >Customer ID: {{ customerDetail.customer_code }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ຊື່ ແລະ ນາມສະກຸນ:
                    <span class="customer"
                    >{{ customerDetail.name }}
                      {{ customerDetail.surname }}</span
                    >
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ຜູ້ຮັບຜິດຊອບ:
                    <span class="customer">{{
                        customerDetail.organization_name
                      }}</span>
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ເບີໂທ:
                    <span class="customer">{{ customerDetail.phone }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    Email:
                    <span class="customer">{{ customerDetail.email }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ປະເພດລູກຄ້າ:
                    <span class="customer">{{
                        customerDetail.customer_type
                      }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ເວລາຈັດສົ່ງ:
                    <span class="customer">{{
                        getStatusType(customerDetail.deliverable_time)
                      }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ທີ່ຢູ່:
                    <span class="customer"
                    >{{ customerDetail.village }},
                      {{ customerDetail.district }},
                      {{ customerDetail.proName }} [{{
                        customerDetail.address
                      }}]</span
                    >
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ທີ່ມາຂອງລູກຄ້າ:
                    <span class="customer">{{
                        source(customerDetail.source)
                      }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ຍອດສະສົມຕຸກນ້ຳ:
                    <span class="customer error--text">{{
                        customerDetail.accumulated_purchase_number
                      }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ຄະແນນສະສົມ:
                    <span class="customer error--text">{{
                        customerDetail.point
                      }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" v-if="customerDetail.package">
                  <p class="invoice">
                    ປະເພດແພັກເກດ:
                    <span class="primary--text"
                    >{{ customerDetail.package.name }}
                      ລາຄາຕໍ່ຕຸກ
                      {{ customerDetail.package.price }}</span
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    Created By:
                    <span class="customer">{{
                        customerDetail.created_by
                      }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ສະຖານະ:
                    <span :class="getstatusColor(customerDetail.status)">{{
                        customerDetail.status
                      }}</span>
                  </p>
                </v-col>
              </v-row>
              <!-- <v-row class="justify-end">
                    <v-col cols="12" class="d-flex justify-end">
                      <p class="invoice justify-end">ທີ່ຢູ່: {{customerDetail.village}}, {{customerDetail.district}}, {{customerDetail.proName}} [{{customerDetail.address}}]</p>
                    </v-col>
                  </v-row> -->
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeModalView()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>

    <v-dialog v-model="modalConfirmClose" max-width="700px">
      <template>
        <v-card>
          <v-card-text>
            <v-card-title>ປິດການໃຊ້ງານລູກຄ້າ</v-card-title>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-col cols="12">
                  <v-text-field
                      label="ໝາຍເຫດ"
                      required
                      v-model="remark"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="modalConfirmClose = false"
              >Cancel
              </v-btn
              >
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="closeCustomerConfirm"
              >OK
              </v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!--Change Password -->
    <v-dialog v-model="dialogChangePassword" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Change Password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      label="New Password*"
                      type="password"
                      v-model="new_password"
                      :rules="passwordRules"
                      required
                      @keyup.enter="ChangePassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialogChangePassword = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="loading"
                @click="ChangePassword"
            >
              Change
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog
        v-model="updatePoint"
        persistent
        width="800px"
        max-with="1200px"
        loading
    >
      <template @close="close">
        <v-card>
          <v-card-title>
            <h4>ເພີ່ມຄະແນນສະສົມ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Point *"
                        required
                        solo
                        clearable
                        outlined
                        type="number"
                        class="input-number"
                        v-model="edit_customer.point"
                        @keyup.enter="updateCusPoint"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  class="mr-2"
                  text
                  @click="updatePoint = false"
              >
                Close
              </v-btn>
              <v-btn
                  color="success"
                  class="mr-2"
                  depressed
                  :loading="loading"
                  :disabled="loading"
                  @click="updateCusPoint()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>

    <!-- ===========Bottle From Branch Detail -->
    <v-dialog
        v-model="updateMoney"
        persistent
        width="800px"
        max-with="1200px"
        loading
    >
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ເພີ່ມເງິນມັດຈຳ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ຈຳນວນຕຸກນ້ຳ *"
                        required
                        v-model="addValue.amount"
                        type="number"
                        class="input-number"
                        :rules="bottleRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="ຈຳນວນເງິນມັດຈຳ *"
                        required
                        v-model="addValue.total"
                        type="number"
                        class="input-number"
                        :rules="totalRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="closeUpdateBondingFees()"
              >
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="updateBondingFees()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
<!--Show image-->
    <v-dialog
        v-model="showImage"
        width="800px"
        max-with="1200px"
        loading
    >
      <template @close="close">
        <v-card>
          <v-img :src="fullImage">
          </v-img>
        </v-card>
      </template>
    </v-dialog>

  </div>
</template>

<script>
import manage_customer from "../../../../mixins/customer/manage_customer";
import AddVillage from "@/views/Users/Customer/addVillage";
import Pagination from "@/plugins/pagination/pagination";

export default {
  mixins: [manage_customer],
  components: {
    AddVillage,
    Pagination,
  },
  metaInfo() {
    return {
      title: `ລູກຄ້າ - ທີ່ໃຊ້ບໍລິການ`,
      meta: [
        { name: 'description', content: 'ລູກຄ້າ - ທັງໝົດ'},
      ]
    }
  },
  created() {
   this.fetchData();
  }
};
</script>

<style scoped>
/*@import "../../../../../public/scss/main.scss";*/
.customer {
  color: #000;
  font-size: 18px;
}

.invoice {
  font-size: 18px;
}

.primary-color {
  color: #00c1d2;
}

.text-field {
  border-color: #00c1d2;
  padding: 12px 8px 12px 8px;
  width: 100%;
  margin-bottom: 12px;
  font-size: 16px;
}
</style>
