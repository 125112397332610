<template>
  <div>
    <v-row class="mb-2">
      <v-col>
        <h2>
          ລູກຄ້າໃໝ່:
          <span class="primary-color">({{ countNew }})</span>
        </h2>
      </v-col>
<!--      <v-col>-->
<!--        <v-text-field-->
<!--            outlined-->
<!--            dense-->
<!--            clearable-->
<!--            label="ຄົ້ນຫາ ດ້ວຍລະຫັດ, ຊື່, ເບີໂທ"-->
<!--            type="text"-->
<!--            v-model="search"-->
<!--            prepend-inner-icon="mdi-magnify"-->
<!--            @keyup.enter="Search()"-->
<!--        >-->
<!--        </v-text-field>-->
<!--      </v-col>-->
<!--      <v-col class="al-end" col="2">-->
<!--        <v-btn-->
<!--            color="primary"-->
<!--            @click="exportData()"-->
<!--            :loading="exportLoading"-->
<!--            :disabled="exportLoading"-->
<!--        >Export-->
<!--        </v-btn-->
<!--        >-->
<!--      </v-col-->
<!--      >-->
    </v-row>

<!--    <v-row justify="end">-->
<!--      <template>-->
<!--        <v-btn class="btn-primary" fab small dark @click="routeCreate()">-->
<!--          <v-icon>mdi-plus</v-icon>-->
<!--        </v-btn>-->
<!--      </template>-->
<!--    </v-row>-->

    <v-data-table
        :headers="headers"
        :items="new_customers"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <!--Action -->

      <template v-slot:item.customer_code="{item}">
        <v-chip v-if="item.customer_code" color="success">{{ item.customer_code }}</v-chip>
      </template>

      <template v-slot:item.profile="{item}">
        <v-avatar
            size="36px"
        >
          <img v-if="item.profile" :src="item.profile"/>
        </v-avatar>
      </template>
      <template v-slot:item.village="{ item }">
        <div>{{ item.village }}, {{ item.district }}</div>
      </template>
      <template v-slot:item.status="{ item }">
        <div :class="getstatusColor(item.status)">{{ item.status }}</div>
      </template>
      <template v-slot:item.days="{ item }">
        <div
            v-for="(data, index) in item.delivery_day"
            :key="index"
        >
          <div>{{ data.name }},</div>
        </div>
      </template>
      <template v-slot:item.createdDate="{ item }">
        <div :class="getDateCreated(item.createdDate)">
          {{ moment(item.createdDate).format("DD-MM-YYYY") }}
        </div>
        <!-- {{item.created_at}} -->
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="viewInvoice(item)">mdi-eye</v-icon>
        <v-icon small class="mr-2" @click="updateCustomer(item.id)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)"> mdi-delete</v-icon>
      </template>
      <template></template>
    </v-data-table>
    <br/>
    <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchNewCustomer()"
    ></Pagination>
    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loadingView"
              :disabled="loadingView"
              @click="deleteItemConfirm"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
    <AddVillage @filter_village="RefreshVillage()"/>
    <!-- View Modal -->

    <ModalView>
      <template @close="close">
        <v-card mdi-map-marker-circle>
          <v-card-title>
            <span class="headline"
            >Customer ID: {{ customerDetail.customer_code }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ຊື່ ແລະ ນາມສະກຸນ:
                    <span class="customer"
                    >{{ customerDetail.name }}
                      {{ customerDetail.surname }}</span
                    >
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ຜູ້ຮັບຜິດຊອບ:
                    <span class="customer">{{
                        customerDetail.organization_name
                      }}</span>
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ເບີໂທ:
                    <span class="customer">{{ customerDetail.phone }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    Email:
                    <span class="customer">{{ customerDetail.email }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ປະເພດລູກຄ້າ:
                    <span class="customer">{{
                        customerDetail.customer_type
                      }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ເວລາຈັດສົ່ງ:
                    <span class="customer">{{
                        getStatusType(customerDetail.deliverable_time)
                      }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ທີ່ຢູ່:
                    <span class="customer"
                    >{{ customerDetail.village }},
                      {{ customerDetail.district }},
                      {{ customerDetail.proName }} [{{
                        customerDetail.address
                      }}]</span
                    >
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ທີ່ມາຂອງລູກຄ້າ:
                    <span class="customer">{{
                        source(customerDetail.source)
                      }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    ຍອດສະສົມຕຸກນ້ຳ:
                    <span class="customer error--text">{{
                        customerDetail.accumulated_purchase_number
                      }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ຄະແນນສະສົມ:
                    <span class="customer error--text">{{
                        customerDetail.point
                      }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" v-if="customerDetail.package">
                  <p class="invoice">
                    ປະເພດແພັກເກດ:
                    <span class="primary--text"
                    >{{ customerDetail.package.name }}
                      ລາຄາຕໍ່ຕຸກ
                      {{ customerDetail.package.price }}</span
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <p class="invoice">
                    Created By:
                    <span class="customer">{{
                        customerDetail.created_by
                      }}</span>
                  </p>
                </v-col>
                <v-col cols="6">
                  <p class="invoice">
                    ສະຖານະ:
                    <span :class="getstatusColor(customerDetail.status)">{{
                        customerDetail.status
                      }}</span>
                  </p>
                </v-col>
              </v-row>
              <!-- <v-row class="justify-end">
                    <v-col cols="12" class="d-flex justify-end">
                      <p class="invoice justify-end">ທີ່ຢູ່: {{customerDetail.village}}, {{customerDetail.district}}, {{customerDetail.proName}} [{{customerDetail.address}}]</p>
                    </v-col>
                  </v-row> -->
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeModalView()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>

    <v-dialog v-model="modalConfirmClose" max-width="700px">
      <template>
        <v-card>
          <v-card-text>
            <v-card-title>ປິດການໃຊ້ງານລູກຄ້າ</v-card-title>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-col cols="12">
                  <v-text-field
                      label="ໝາຍເຫດ"
                      required
                      v-model="remark"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="modalConfirmClose = false"
              >Cancel
              </v-btn
              >
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="closeCustomerConfirm"
              >OK
              </v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import new_customer from "../../../../mixins/customer/new_customer";
import AddVillage from "@/views/Users/Customer/addVillage";
import Pagination from "@/plugins/pagination/pagination";

export default {
  mixins: [new_customer],
  components: {
    AddVillage,
    Pagination,
  },
  metaInfo() {
    return {
      title: `ລູກຄ້າ - ໃໝ່`,
      meta: [
        { name: 'description', content: 'ລູກຄ້າ - ໃໝ່'},
      ]
    }
  },
  created() {
    this.fetchNewCustomer();
  }
};
</script>

<style scoped>
/*@import "../../../../../public/scss/main.scss";*/
.customer {
  color: #000;
  font-size: 18px;
}

.invoice {
  font-size: 18px;
}

.primary-color {
  color: #00c1d2;
}

.text-field {
  border-color: #00c1d2;
  padding: 12px 8px 12px 8px;
  width: 100%;
  margin-bottom: 12px;
  font-size: 16px;
}
</style>
