<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row justify="end">
          <v-col><h2>ລາຍງານລູກຄ້າທີ່ບໍມີການເຄື່ອນໄຫວສັ່ງຊື້ນ້ຳ</h2></v-col>
          <v-col class="al-end" col="2">
            <v-btn
                color="primary"
                @click="exportData()"
                :loading="exportLoading"
                :disabled="exportLoading"
            >Export</v-btn
            ></v-col
          >
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="4">
            <h3>
              ລວມຈຳນວນລູກຄ້າ:
              {{ Intl.NumberFormat().format(totalCustomer) }}
            </h3>
          </v-col>
          <v-col cols="4" class="d-flex">
            <v-select
              v-model="selectDay"
              :items="day"
              label="ມື້ສັ່ງຊື້ຜ່ານມາລ່າສຸດ"
              item-text="disPlay"
              item-value="value"
              clearable
              dense
              outlined
              @input="fetchData()"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              dense
              clearable
              label="ຄົ້ນຫາ ດ້ວຍ ລະຫັດ, ຊື່"
              type="text"
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              @keyup.enter="Search()"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- <hr /> -->
        <v-data-table
          :headers="headers"
          :items="listCustomers"
          :disable-pagination="true"
          hide-default-footer
          class="elevation-1"
          :loading="TableLoading"
          :disabled="TableLoading"
          loading-text="Loading... Please wait"
          :search="search"
        >
          <template v-slot:item.name="{ item }">
            {{ item.name }} ({{ item.village }}, {{ item.district }})
          </template>
          <template v-slot:item.totalBondingFee="{ item }">
            {{ Intl.NumberFormat().format(item.totalBondingFee) }}
          </template>
          <template v-slot:item.debtTotalBondingFee="{ item }">
            {{ Intl.NumberFormat().format(item.debtTotalBondingFee) }}
          </template>
          <template v-slot:item.totalPriceInvoice="{ item }">
            {{ Intl.NumberFormat().format(item.totalPriceInvoice) }}
          </template>
          <template v-slot:item.latestInvoice="{ item }">
            {{ moment(item.createdDate).format("DD-MM-YYYY") }}
          </template>
          <!--Action -->
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="viewCustomer(item.id)"
              >mdi-eye</v-icon
            >
          </template>
        </v-data-table>
        <br />
        <template>
          <Pagination
            v-if="pagination.last_page > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchData()"
          ></Pagination>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
import { GetOldValueOnInput } from "@/Helpers/GetValue";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      start_menu: false,
      end_menu: false,
      loading: false,
      TableLoading: false,
      exportLoading:false,
      listCustomers: [],
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 70,
      totalCustomer: 0,
      search: "",
      oldVal: "",
      selectDay: "",
      day: [
        {
          value: 7,
          disPlay: "7 ມື້",
        },
        {
          value: 10,
          disPlay: "10 ມື້",
        },
        {
          value: 14,
          disPlay: "14 ມື້",
        },
        {
          value: 20,
          disPlay: "20 ມື້",
        },
        {
          value: 25,
          disPlay: "25 ມື້",
        },
        {
          value: 30,
          disPlay: "30 ມື້",
        },
        {
          value: 45,
          disPlay: "45 ມື້",
        },
        {
          value: 60,
          disPlay: "60 ມື້",
        },
        {
          value: 75,
          disPlay: "75 ມື້",
        },
        {
          value: 90,
          disPlay: "90 ມື້",
        },
        {
          value: 120,
          disPlay: "4 ເດືອນຂື້ນໄປ",
        },
      ],
      headers: [
        { text: "ຊື່ລູກຄ້າ", align: "start", value: "name", sortable: false },
        { text: "ເບີໂທ", align: "start", value: "phone", sortable: false },
        { text: "ຈຳນວນຕຸກນ້ຳ", value: "total_bottle", align: "center" },
        {
          text: "ຕຸກສະສົມ",
          value: "accumulated_purchase_number",
          align: "center",
        },
        // { text: "ຄ່າມັດຈຳ", value: "totalBondingFee", align: "center" },
        // {
        //   text: "ຄ່າມັດຈຳຍັງຄ້າງ",
        //   value: "debtTotalBondingFee",
        //   align: "center",
        // },
        // { text: "ລວມຄ່ານ້ຳ", value: "totalPriceInvoice", align: "center" },
        { text: "ຄະແນນ", value: "point", align: "center" },
        {
          text: "ຈຳນວນບິນ",
          value: "countInvoice",
          align: "center",
        },
        {
          text: "ວັນທີຊື້ລ່າສຸດ",
          value: "latestInvoice",
          align: "center",
        },
        { text: "View", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  metaInfo() {
    return {
      title: `ລາຍງານລູກຄ້າອາດຈະບໍ່ຊື້ນ້ຳ`,
      meta: [
        { name: 'description', content: 'ລາຍງານລູກຄ້າອາດຈະບໍ່ຊື້ນ້ຳ'},
      ]
    }
  },
  methods: {
    viewCustomer(id) {
      this.$router.push({
        name: "Report-Customer-Detail",
        params: { id: id },
      });
    },
    fetchData() {
      this.TableLoading = true;
      this.$axios
        .post("list/customer/for/stop/sales", {
          page: this.pagination.current_page,
          per_page: this.per_page,
          lastDate: this.selectDay,
          search: this.search,
        })
        .then((res) => {
          setTimeout(() => {
            this.listCustomers = res.data.listCustomers.data;
            this.totalCustomer = res.data.listCustomers.total;
            this.pagination = res.data.listCustomers;
            this.TableLoading = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$axios
          .post("export/customer/sometime/service",
              {
              lastDate:this.selectDay
            }
              , { responseType: "blob" })
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "report-customer" + ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    Search() {
      GetOldValueOnInput(this);
    },
  },

  watch: {
    search: function (value) {
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
