<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row justify="end">
          <v-col><h2>ສະຫຼຸບການຊື້ນ້ຳຂອງລູກຄ້າ</h2></v-col>
          <!--  <v-col class="al-end"> <v-btn color="primary">Export</v-btn></v-col>-->
        </v-row>
        <v-row>
          <v-col>
            <h3>
              ລວມຈຳນວນລູກຄ້າທີ່ຊື້ນ້ຳ:
              {{ Intl.NumberFormat().format(totalCustomer)}}
            </h3>
          </v-col>
          <v-col class="d-flex">
            <v-select
                v-model="selectedPayment"
                :items="payment_Types"
                label="ປະເພດການຊຳລະ"
                item-text="name"
                item-value="value"
                dense
                outlined
                @input="fetchData()"
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              clearable
              label="ຄົ້ນຫາ ດ້ວຍ ລະຫັດ, ຊື່"
              type="text"
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              @keyup.enter="Search()"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="saleToCustomer"
          :disable-pagination="true"
          hide-default-footer
          class="elevation-1"
          :loading="TableLoading"
          :disabled="TableLoading"
          loading-text="Loading... Please wait"
          :search="search"
        >
          <template v-slot:item.name="{ item }">
            {{ item.name }} ({{ item.village }})
          </template>
          <template v-slot:item.totalBondingFee="{ item }">
            {{ Intl.NumberFormat().format(item.totalBondingFee) }}
          </template>
          <template v-slot:item.debtTotalBondingFee="{ item }">
            {{ Intl.NumberFormat().format(item.debtTotalBondingFee) }}
          </template>
          <template v-slot:item.totalPriceInvoice="{ item }">
            {{ Intl.NumberFormat().format(item.totalPriceInvoice) }}
          </template>
          <template v-slot:item.point="{ item }">
            <v-chip color="success">{{ item.point }}</v-chip>
          </template>
          <!--Action -->
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="viewCustomer(item.id)"
              >mdi-eye</v-icon
            >
          </template>
        </v-data-table>
        <br />
        <template>
          <Pagination
            v-if="pagination.last_page > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchData()"
          ></Pagination>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
import { GetOldValueOnInput } from "@/Helpers/GetValue";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      start_menu: false,
      end_menu: false,
      loading: false,
      bankLoading: false,
      TableLoading: false,
      saleToCustomer: [],
      // invoiceDetail: {},
      // customerDetail: {},
      // createdByDetail: {},
      selectedPayment: "all",
      defaultValue: {
        id: "all",
        name: "ທັງໝົດ",
      },
      payment_Types: [
        {
          value: "all",
          name: "ທັງໝົດ",
        },
        {
          value: "cash",
          name: "ເງິນສົດ",
        },
        {
          value: "bank",
          name: "ທະນາຄານ",
        },
        {
          value: "support",
          name: "ສະໜັບສະໜູນ",
        },
        {
          value: "debt",
          name: "ຕິດໜີ້",
        },
      ],
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 70,
      totalCustomer: 0,
      search: "",
      oldVal: "",

      headers: [
        { text: "ຊື່ລູກຄ້າ", align: "start", value: "name", sortable: false },
        { text: "ຈຳນວນບິນ", value: "countInvoice", align: "center" },
        { text: "ຈຳນວນຕຸກນ້ຳ", value: "total_bottle", align: "center" },
        {
          text: "ຕຸກສະສົມ",
          value: "accumulated_purchase_number",
          align: "center",
        },
        { text: "ຄ່າມັດຈຳ", value: "totalBondingFee", align: "center" },
        {
          text: "ຄ່າມັດຈຳຍັງຄ້າງ",
          value: "debtTotalBondingFee",
          align: "center",
        },
        { text: "ລວມຄ່ານ້ຳ", value: "totalPriceInvoice", align: "center" },
        { text: "ຄະແນນ", value: "point", align: "center" },
        {
          text: "ຈຳນວນຕຸກມັດຈຳ",
          value: "totalBondingAmountBottle",
          align: "center",
        },
        // {
        //   text: "ວັນທີຊື້ລ່າສຸເ",
        //   value: "createdDate",
        //   align: "center",
        // },
        { text: "View", value: "actions", sortable: false, align: "center" },
      ],
    };
  },
  metaInfo() {
    return {
      title: `ລາຍງານຍອດຂາຍ - ລູກຄ້າ`,
      meta: [
        { name: 'description', content: 'ລູກຄ້າ - ແກ້ໄຂ'},
      ]
    }
  },
  methods: {
    viewCustomer(id) {
      this.$router.push({
        name: "Report-Customer-Detail",
        params: { id: id },
      });
    },
    fetchData() {
      this.TableLoading = true;
      this.$axios
        .get("list/invoice/customers", {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.search,
            payment_type:this.selectedPayment
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.saleToCustomer = res.data.listCustomers.data;
            this.totalCustomer = res.data.listCustomers.total;
            this.pagination = res.data.listCustomers;
            this.TableLoading = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    Search() {
      GetOldValueOnInput(this);
    },
  },

  watch: {
    search: function (value) {
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    // this.viewInvoice();
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
