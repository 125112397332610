var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',[_c('h2',[_vm._v("ສະຫຼຸບການຊື້ນ້ຳຂອງລູກຄ້າ")])])],1),_c('v-row',[_c('v-col',[_c('h3',[_vm._v(" ລວມຈຳນວນລູກຄ້າທີ່ຊື້ນ້ຳ: "+_vm._s(Intl.NumberFormat().format(_vm.totalCustomer))+" ")])]),_c('v-col',{staticClass:"d-flex"},[_c('v-select',{attrs:{"items":_vm.payment_Types,"label":"ປະເພດການຊຳລະ","item-text":"name","item-value":"value","dense":"","outlined":""},on:{"input":function($event){return _vm.fetchData()}},model:{value:(_vm.selectedPayment),callback:function ($$v) {_vm.selectedPayment=$$v},expression:"selectedPayment"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","label":"ຄົ້ນຫາ ດ້ວຍ ລະຫັດ, ຊື່","type":"text","prepend-inner-icon":"mdi-magnify"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.Search()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.saleToCustomer,"disable-pagination":true,"hide-default-footer":"","loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ("+_vm._s(item.village)+") ")]}},{key:"item.totalBondingFee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.totalBondingFee))+" ")]}},{key:"item.debtTotalBondingFee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.debtTotalBondingFee))+" ")]}},{key:"item.totalPriceInvoice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.totalPriceInvoice))+" ")]}},{key:"item.point",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"success"}},[_vm._v(_vm._s(item.point))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewCustomer(item.id)}}},[_vm._v("mdi-eye")])]}}])}),_c('br'),[(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchData()}}}):_vm._e()]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }