<template>
  <div>
    <ModalAdd2>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span class="headline">Add Village</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      required
                      v-model="selectedDistrict"
                      :items="listDistricts"
                      item-text="name"
                      item-value="id"
                      label="ເລືອກເມືອງ*"
                      :rules="ruleDistrict"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Name*"
                      required
                      v-model="village.name"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="CloseAddVillage()">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="AddItem()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ModalAdd2>
  </div>
</template>

<script>
import manage_customer from "@/mixins/customer/manage_customer";
export default {
  mixins: [manage_customer],
  methods: {
    CloseAddVillage() {
      this.$store.commit("modalAdd2_State", false);
    },

    AddItem() {
      if (this.$refs.form.validate() == true) {
        this.SubmitItem();
      }
    },
    SubmitItem() {
      this.village.district_id = this.selectedDistrict;
      this.loading = true;
      this.$admin
        .post("add-village", this.village)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.loading = false;
              this.CloseAddVillage();
              this.village = {};
              this.$emit("filter_village");
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
          this.getData();
          this.reset();
          if (error.response.status == 422) {
            var obj = error.response.data.errors;
            for (let [key, village] of Object.entries(obj)) {
              this.server_errors[key] = village[0];
            }
          }
        });
    },
  },
};
</script>

<style>
</style>