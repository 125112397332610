import {GetOldValueOnInput} from "@/Helpers/GetValue";

export default {
    data() {
        return {
            loading: false,
            loadingView: false,
            TableLoading: false,
            modalConfirmClose: false,
            exportLoading: false,
            newCustomer: false,
            closeId: "",
            remark: "",
            customerType: [],
            customerCode: '',
            selectedCustomerType: "",
            customerTypeId: "",
            customerID: "",
            selectedDistrict: "",
            selectedVillage: "",
            listDistricts: [],
            listVillages: [],
            selectedDistrictAdd: "",
            filterVillages: [],
            packages: [],
            package_Id: "",
            selectedPackage: "",
            server_errors: {
                email: "",
                customer_code: "",
                phone: "",
            },
            new_customers:[],
            countNew:0,
            customer: {},
            customers: [],
            customerDetail: [],
            rolesID: {},
            village: {},
            //Pagination
            offset: 15,
            pagination: {},
            per_page: 70,
            oldVal: "",
            search: '',
            //Location
            // package
            selectedItem: '',
            timer: null,

            // Service-Zone
            checkedServiceZone: [],
            status: [
                {
                    id: 1,
                    name: "pending",
                },
                {
                    id: 2,
                    name: "approved",
                },
                {
                    id: 3,
                    name: "reject",
                },
            ],
            statusValue: "",
            sources: [
                {
                    id: 1,
                    value: "online",
                    name: "ທາງອອນໄລ",
                },
                {
                    id: 2,
                    value: "booth",
                    name: "ລົງບູດ",
                },
                {
                    id: 3,
                    value: "recommendation",
                    name: "ຄົນຮູ້ຈັກແນະນຳ",
                },
                {
                    id: 4,
                    value: "direct_to_customer",
                    name: "ລົງຫາລູກຄ້າ",
                },
                {
                    id: 5,
                    value: "instant_customer",
                    name: "ລູກຄ້າລະຫວ່າງທາງ",
                },
            ],
            sourceValue: "",
            deliverable_time: [
                {
                    id: 1,
                    value: "9:00_to_16:00_narmal_time",
                    name: "9:00 ຫາ 16:00",
                },
                {
                    id: 2,
                    value: "18:00_to_20:00_special_time",
                    name: "18:00 ຫາ 20:00",
                },
                {
                    id: 3,
                    value: "weekly",
                    name: "ຈັດສົ່ງທຸກທິດ",
                },
                {
                    id: 4,
                    value: "2_per_weekly",
                    name: "ຈັດສົ່ງ 2 ທິດຄັ້ງ",
                },
                {
                    id: 5,
                    value: "monthly",
                    name: "ຈັກສົ່ງເດືອນລະຄັ້ງ",
                },
                {
                    id: 6,
                    value: "onCall",
                    name: "ໝົດເເລ້ວຈະໂທຫາ",
                },
            ],

            deliverable_time_value: "",
            headers: [
                {text: "Actions", value: "actions", sortable: false,width: "120px"},
                {
                    text: "ລະຫັດລູກຄ້າ",
                    align: "start",
                    value: "customer_code",
                    sortable: false
                },
                { text: "Profile", value: "profile",width: "120px" },
                {text: "ວັນທີ", value: "createdDate", sortable: true,width: "150px"},
                {
                    text: "ຊື່",
                    align: "start",
                    value: "name",
                },
                {
                    text: "ປະເພດ",
                    align: "start",
                    value: "customer_type",
                    width: "150px"
                },

                {
                    text: "ຜູ້ຮັບຜິດຊອບ",
                    align: "start",
                    value: "organization_name", sortable: false,width: "150px"
                },
                {text: "ເບີໂທ", value: "phone", sortable: false},
                // { text: "Email", value: "email" },
                {text: "ທີ່ຢູ່", value: "village",width: "150px"},
                { text: "ຈຸດສັງເກດທີ່ຢູ່", value: "ຈຸດສັງເກດ",width: "150px" },
                {text: "ແພັກເກດ", value: "package.name", sortable: false,width: "150px"},
                {
                    text: "ວັນຈັດສົ່ງ",
                    align: "start",
                    value: "days",
                    sortable: false,
                    width: "100px"
                },
                {text: "ສະຖນານະ", value: "status", sortable: false,width: "120px"},
            ],
            toast: {
                value: true,
                color: "success",
                msg: "Success",
            },
            toast_error: {
                value: true,
                color: "error",
                msg: "Something when wrong!",
            },
        };
    },
    methods: {
        OpenModalAdd() {
            this.$store.commit("modalAdd_State", true);
        },

        AddItem() {
            if (this.$refs.form.validate() == true) {
                this.SubmitItem();
            }
        },


        CloseModalAdd() {
            this.customer = {};
            this.reset();
            this.fetchData();
            this.$store.commit("modalAdd_State", false);
        },
        routeBack() {
            this.$router.push({name: 'Customer'});
        },

        updateCustomer(id) {
            this.$router.push({name: 'CustomerUpdate', params: {id: id}});
        },
        OpenModalEdit(item) {
            this.customer_edit = item;
            this.$store.commit("modalEdit_State", true);
        },
        //
        // fetchData() {
        //     this.TableLoading = true;
        //     this.$admin.get('customer', {
        //         params: {
        //             page: this.pagination.current_page,
        //             per_page: this.per_page,
        //             search: this.search
        //         }
        //     }).then(res => {
        //         setTimeout(() => {
        //             this.customers = res.data.customers.data;
        //             this.count = res.data.countCustomer;
        //             this.pagination = res.data.customers;
        //             this.TableLoading = false;
        //         }, 500);
        //     }).catch(() => {
        //         this.TableLoading = false;
        //         this.$store.commit("Toast_State", this.toast_error);
        //     });
        //
        //     this.$axios
        //         .get("list-customer-types")
        //         .then((res) => {
        //             this.customerType = res.data.customerTypes;
        //             this.TableLoading = false;
        //         })
        //         .catch(() => {
        //             this.TableLoading = false;
        //             this.$store.commit("Toast_State", this.toast_error);
        //         });
        //
        //     this.$admin
        //         .get("list-ຈຸດສັງເກດ")
        //         .then((res) => {
        //             //  this.listVillages = res.data.listVillages;
        //             this.listDistricts = res.data.districts;
        //             this.filterVillages = res.data.listVillages;
        //             this.TableLoading = false;
        //         })
        //         .catch(() => {
        //             this.TableLoading = false;
        //             this.$store.commit("Toast_State", this.toast_error);
        //         });
        //
        //     this.$admin
        //         .get("list-packages")
        //         .then((res) => {
        //             setTimeout(() => {
        //                 this.packages = res.data.listPackages;
        //                 this.TableLoading = false;
        //             }, 100);
        //         })
        //         .catch(() => {
        //             this.TableLoading = false;
        //             this.$store.commit("Toast_State", this.toast_error);
        //         });
        // },

        fetchNewCustomer() {
            this.TableLoading = true;
            this.$admin.get('new-customer', {
                params: {
                    page: this.pagination.current_page,
                    per_page: this.per_page,
                }
            }).then(res => {
                setTimeout(() => {
                    this.new_customers = res.data.customers.data;
                    this.countNew = res.data.count;
                    this.pagination = res.data.customers;
                    this.TableLoading = false;
                }, 500);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });

        },

        FilterVillages(districtId) {
            const result_checking = this.filterVillages.filter((item) => {
                return item.district_id == districtId;
            });
            this.listVillages = result_checking;
            this.selectedVillage = {...this.listVillages[0]};
        },

        closeCustomer(id) {
            this.closeId = id;
            this.modalConfirmClose = true;
        },
        closeCustomerConfirm() {
            this.loading = true;
            this.$admin
                .post("close-customer-service/" + this.closeId, {remark: this.remark})
                .then((res) => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.fetchData();
                            this.modalConfirmClose = false;
                            this.loading = false;
                            this.remark = "";
                            this.$store.commit("Toast_State", this.toast);
                        }, 300);
                    }
                })
                .catch(() => {
                    this.fetchData();
                    this.$store.commit("Toast_State", this.toast_error);
                    this.modalConfirmClose = false;
                    this.loading = false;
                });

        },

        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        },

        deleteItem(id) {
            this.customerID = id;
            this.$store.commit("modalDelete_State", true);
        },

        deleteItemConfirm() {
            this.loading = true;
            this.$admin
                .delete("delete-customer/" + this.customerID)
                .then((res) => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.fetchNewCustomer();
                            this.$store.commit("modalDelete_State", false);
                            this.loading = false;
                            this.$store.commit("Toast_State", this.toast);
                        }, 300);
                    }
                })
                .catch(() => {
                    this.$store.commit("Toast_State", this.toast_error);
                    this.$store.commit("modalDelete_State", false);
                    this.loading = false;
                });
        },
        reset() {
            this.$refs.form.reset();
        },

        RefreshVillage() {
            this.$admin
                .get("list-ຈຸດສັງເກດ")
                .then((res) => {
                    this.filterVillages = res.data.listVillages;
                    this.TableLoading = false;
                    this.FilterVillages(this.selectedDistrict);
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },

        OpenAddVillage() {
            this.$store.commit("modalAdd2_State", true);
        },

        CloseAddVillage() {
            this.$store.commit("modalAdd2_State", false);
        },
        getstatusColor(status) {
            if (status == "pending") return "primary--text";
            else if (status == "approved") return "success--text";
            else if (status == "reject") return "error--text";
            else return;
        },
        getStatusType(time) {
            if (time == '9:00_to_16:00_narmal_time') return '9:00 ຫາ 16:00 ເວລາປົກກະຕິ';
            else if (time == '18:00_to_20:00_special_time') return '18:00 ຫາ 20:00 ເວລາພິເສດ';
            else return;
        },
        source(s) {
            if (s == 'online') return 'ທາງອອນໄລ';
            else if (s == 'booth') return 'ລົງບູດ';
            else if (s == 'recommendation') return 'ຄົນຮູ້ຈັກແນະນຳ';
            else if (s == 'direct_to_customer') return 'ລົງຫາລູກຄ້າ';
            else if (s == 'instant_customer') return 'ລູກຄ້າລະຫວ່າງທາງ';
            else return;
        },

        getDateCreated(date) {
            let date_now = new Date(Date.now());
            date_now = this.moment(date_now).format("YYYY-MM-DD");
            if (this.moment(date).format("YYYY-MM-DD") == date_now) {
                return "error--text";
            } else {
                return "";
            }
        },

        viewInvoice(value) {
            this.loadingView = true;
            this.customerDetail = value;
            this.$store.commit("modalView_State", true);
        },
        closeModalView() {
            this.loadingView = false;
            this.$store.commit("modalView_State", false);
        },
        //Export excel
        exportData() {
            this.exportLoading = true;
            this.$axios
                .get(
                    "export/customers", {responseType: "blob"}
                )
                .then((res) => {
                    setTimeout(() => {
                        const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                        const fileLink = document.createElement("a");
                        fileLink.href = fileUrl;
                        fileLink.setAttribute(
                            "download",
                            "customers" +
                            ".xlsx"
                        );
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        document.body.removeChild(fileLink);
                        this.exportLoading = false;
                    }, 100);
                })
                .catch(() => {
                    this.exportLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },

        ///Search
        Search() {
            GetOldValueOnInput(this);
        },
    },
    watch: {
        selectedDistrict: function (dist_id) {
            this.FilterVillages(dist_id);
        },
        "customer_edit.district_id": function (value) {
            this.FilterVillages(value);
        },

        "customer.email": function () {
            this.server_errors.email = "";
        },
        "customer.phone": function () {
            this.server_errors.phone = "";
        },
        "customer.customer_code": function () {
            this.server_errors.customer_code = "";
        },

        'customer.price': function () {
            this.server_errors.price = '';
        },
        'customer.bottle_giveaway': function (value) {
            this.accumulateBottle(value);
        },
        'customer.point_giveaway': function (value) {
            this.accumulatePoint(value);
        },

        "customer.free_first_customer": function (value) {
            this.firstSaleDiscount(value);
        },
        'search': function (value) {
            if (value == '') {
                this.fetchData();
            }
        }
    },

    created() {
        // this.fetchNewCustomer();
    },
};
